<template>
  <ShipmentAdd />
</template>

<script>
import ShipmentAdd from "@/components/shipments/ShipmentAdd";
export default {
  name: "AddShipments",
  components: { ShipmentAdd }
};
</script>

<style scoped></style>
