<template>
  <Page title="Add Shipment">
    <template>
      <v-row dense>
        <v-col>
          <v-autocomplete
            :items="filteredLabs"
            :filter="customFilter"
            :rules="rules.required"
            label="Select a Lab"
            no-data-text="No matches were found"
            item-text="title"
            item-value="id"
            :value="value"
            v-model="lab_id"
          >
            <template v-slot:label>
              <h4>Select a Lab</h4>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-autocomplete
            :items="allShippingMethods"
            :filter="customFilter"
            label="Select a Shipment Method"
            no-data-text="No matches were found"
            item-text="title"
            item-value="id"
            :value="value"
            @input="emitChange"
            v-model="shipment_method_id"
          >
            <template v-slot:label>
              <h4>Select a Shipment Method</h4>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="tracking_number"
            label="Tracking Number"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters dense>
        <v-col>
          <v-btn
            color="primary"
            class="float-right mb-1 ml-2"
            :disabled="!lab_id"
            @click="saveShipment"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <!-- <LabSelection /> -->

    <!-- <v-form @submit.prevent="">
        <v-row dense>
          <v-col cols="12" :sm="hasMultipleClients ? 5 : 6">
            <v-text-field
              label="ID"
              v-model="options.params.id"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="hasMultipleClients ? 2 : 6">
            <v-checkbox
              v-model="options.params.in_transit"
              label="In Transit"
            ></v-checkbox>
          </v-col>
          <v-col v-if="hasMultipleClients" cols="12" sm="5">
            <v-select
              :items="clients"
              item-value="id"
              item-text="title_with_id"
              v-model="options.params.client_id"
              label="Client"
              clearable
            />
          </v-col>
        </v-row>
      </v-form>
      <v-data-table v-bind="defaultAttrs" :options.sync="options">
        <template v-slot:item.samples="{ item }">
          <v-avatar>
            <v-avatar
              :color="!item.samples.length ? 'error' : 'primary'"
              size="25"
            >
              <span class="white--text">{{ item.samples.length }}</span>
            </v-avatar>
          </v-avatar>
        </template>
        <template v-slot:item.received="{ item }">
          <v-icon :color="item.received ? 'success' : 'warning'">
            {{ item.received ? "mdi-check" : "mdi-progress-clock" }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span class="mx-2">
                <v-icon
                  @click.stop="() => shippingLabelModal(item)"
                  v-bind="attrs"
                  v-on="on"
                  :color="
                    item.easypost_shipment_id.length
                      ? item.tracking_number
                        ? 'primary'
                        : 'warning'
                      : ''
                  "
                >
                  mdi-barcode-scan
                </v-icon>
              </span>
            </template>
            <span>Manage Shipment</span>
          </v-tooltip>
          <v-tooltip
            v-if="
              (item.easypost_shipment_id && !item.date_shipped) ||
                (item.shipment_method_id && !item.date_shipped)
            "
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="mx-2">
                <v-icon
                  class="action-icon"
                  @click.stop="() => resetShipping(item)"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                >
                  mdi-refresh
                </v-icon>
              </span>
            </template>
            <span>Start Shipping Over</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span class="mx-2">
                <v-icon
                  @click.stop="() => shippingStatusModal(item)"
                  v-bind="attrs"
                  v-on="on"
                  :color="
                    item.easypost_shipment_id.length && item.tracking_number
                      ? item.date_shipped
                        ? 'primary'
                        : 'warning'
                      : ''
                  "
                >
                  mdi-truck-delivery
                </v-icon>
              </span>
            </template>
            <span>Shipping Status</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog v-model="shipping_label.active" width="600">
        <ShippingLabel
          v-if="shipping_label.item !== false"
          v-model="shipping_label.item"
          v-on:close-dialog="() => shippingLabelModal(false)"
          v-on:refresh="() => refresh()"
        />
      </v-dialog>
      <v-dialog v-model="shipping_status.active" width="600">
        <ShippingStatus
          v-if="shipping_status.item !== false"
          v-model="shipping_status.item"
          v-on:close-dialog="() => shippingStatusModal(false)"
          v-on:refresh="() => refresh()"
        />
      </v-dialog> -->
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
//   import ShippingLabel from "@/components/shipments/Label/ShippingLabel";
//   import ShippingStatus from "@/components/shipments/ShippingStatus/ShippingStatus";
import dataTablePagination from "@/mixins/dataTablePagination";
import { mapGetters, mapActions } from "vuex";
import rules from "@/utils/rules";
// import LabSelection from "../labs/LabSelection.vue";

export default {
  name: "ShipmentAdd",
  components: { Page },
  mixins: [dataTablePagination],
  data() {
    return {
      shipping_label: {
        active: false,
        item: false
      },
      shipping_status: {
        active: false,
        item: false
      },
      options: {
        params: {
          id: "",
          in_transit: this.$route.query.in_transit,
          client_id: ""
        }
      },
      rules: {
        required: [rules.required],
        alphanumericRequired: [rules.required, rules.alphanumeric],
        description: [rules.maxLength(60)]
      },
      lab_id: null
    };
  },
  computed: {
    ...mapGetters("baseData", {
      getById: "getById",
      allShippingMethods: "shipment_methods"
    }),
    ...mapGetters("baseData", ["labs"]),
    ...mapActions("baseData", ["getLabs"]),
    filteredLabs() {
      return this.labs.filter(lab => lab.is_active === true);
    }
  },
  methods: {
    customFilter(item, queryText) {
      const title = item.title.toLowerCase();
      const searchText = queryText.toLowerCase();

      return title.indexOf(searchText) > -1;
    },
    emitChange(value) {
      this.$emit("input", value);
    },
    ...mapActions("baseData", ["getShippingMethods"]),
    filterInput(event) {
      const newValue = event.target.value.replace(/[^0-9]/g, "");
      this.tracking_number = newValue === "" ? null : newValue;
    },
    async saveShipment() {
      await this.createShipment({
        lab_id: this.lab_id,
        tracking_number: this.tracking_number,
        shipment_method_id: this.shipment_method_id
      }).then(this.$router.push("browse"));
    },
    ...mapActions("shipments", [
      "createShipment",
      "getShipment",
      "deleteShipment"
    ])
  },
  beforeMount() {
    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      this.options.params = Object.assign({}, this.$route.query);
      this.$router.replace({ name: this.$route.name }); // remove query string
    }
  },
  mounted() {
    Promise.all([this.getShippingMethods(), this.getLabs()]).then(
      () => (this.loading = false)
    );
    // console.log(this.allShippingMethods);
    console.log(this.filteredLabs);
  }
};
</script>

<style scoped></style>
